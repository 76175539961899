
import React from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero";
import Section from "../../components/ui/Section/Section";
import { graphql } from "gatsby";
import ThemenGrid, {Tiles} from '../../components/themen/ThemenGrid';

export default ({ data }) => {

    let tiles: Array<Tiles> = [];

    tiles = [
        ...data.allFallbeispieleHJson.edges.map(({node}) => ({
            ...node, 
            type: "fallbeispiel", 
            published: new Date(node.published), 
        })),
        ...data.allLandingpagesHJson.edges.map(({node}) => ({
            type: "landingpage",
            published: new Date(node.published),
            name: node.name,
            kurzbeschreibung: node.description,
            url: node.url,
            listenbild: node.listenbild, 
        })),
        ...data.allNeuigkeitenHJson.edges.map(({node}) => ({
            type: "news",
            published: new Date(node.published),
            name: node.title,
            kurzbeschreibung: node.kurzbeschreibung,
            url: node.url,
            tags: node.tags,
        }))
    ];

    tiles = tiles.sort((a, b) => b.published.getTime() - a.published.getTime());


    return (
        <Layout crumbs={[{ label: "Home", url: "" }, { label: "Themen", url: "themen" }]} headerColor="dark" breadcrumbColor="light" hasWave={false}>

            <Hero
                title="Themen&shy;bereiche"
                subtitle="Wertvolle Themen rund um unsere Arbeit, die wir besonders behandeln und gerne mit Ihnen teilen."
                size="medium"
                color="dark"
                hasWaveBottom={['mobile', 'tablet']}
            />


            <Section>
                <ThemenGrid 
                    tiles={tiles} 
                    columns={{
                        desktop: 4,
                        tablet: 3,
                        mobile: 1,
                    }}
                    itemsPerPage={{
                        desktop: 48,
                        tablet: 24,
                        mobile: 12,
                    }}
                />
            </Section>


        </Layout>
    )
};


export const pageQuery = graphql`{
  allFallbeispieleHJson(limit: 4, filter: {published: {ne: null}}) {
    edges {
      node {
        published
        kurzbeschreibung
        name
        url
        listenbild {
          alt
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  allNeuigkeitenHJson(filter: {published: {ne: null}}) {
    edges {
      node {
        published
        title
        url
        kurzbeschreibung
        tags
      }
    }
  }
  allLandingpagesHJson(filter: {published: {ne: null}}) {
    edges {
      node {
        published
        name
        url
        listenbild {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
        }
        description
      }
    }
  }
}
`